var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card card-custom gutter-b example example-compact"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"disabled":"","dense":"","label":"Summa","outlined":""},model:{value:(_vm.detail.income_amount),callback:function ($$v) {_vm.$set(_vm.detail, "income_amount", $$v)},expression:"detail.income_amount"}})],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.detail.oper_date,"label":"Sana","disabled":"","dense":"","readonly":"","outlined":""}})],1),_c('v-col',{staticClass:"pl-0 py-0",attrs:{"cols":"12"}}),_c('v-col',{staticClass:"pl-0 py-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"disabled":"","value":_vm.detail.income_type_description !== undefined
                    ? _vm.detail.income_type_description.name
                    : '',"dense":"","label":"Asosiy Kirim","outlined":""}})],1),_c('v-col',{staticClass:"pl-0 py-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.detail.income_type_description !== undefined
                    ? _vm.detail.income_type_description.name
                    : '',"no-data-text":"Malumot topilmadi","label":"Kategoriya","outlined":"","disabled":""}})],1),_c('v-col',{staticClass:"pl-0 py-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.detail.income_type_description !== undefined &&
                  _vm.detail.income_type_description !== null
                    ? _vm.detail.income_type_description.name
                    : '',"label":"SubKategoriya","outlined":"","disabled":""}})],1),_c('v-col',{staticClass:"pl-0 py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","disabled":"","label":"Comment"},model:{value:(_vm.detail.comment),callback:function ($$v) {_vm.$set(_vm.detail, "comment", $$v)},expression:"detail.comment"}})],1)],1)],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v("Daromad Malumotlari")])])])
}]

export { render, staticRenderFns }