<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Daromad Malumotlari</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="6" class="pl-0">
                <v-text-field
                  v-model="detail.income_amount"
                  disabled
                  dense
                  label="Summa"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pr-0">
                <v-text-field
                  :value="detail.oper_date"
                  label="Sana"
                  disabled
                  dense
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pl-0 py-0"></v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-text-field
                  disabled
                  :value="
                    detail.income_type_description !== undefined
                      ? detail.income_type_description.name
                      : ''
                  "
                  dense
                  label="Asosiy Kirim"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-text-field
                  dense
                  :value="
                    detail.income_type_description !== undefined
                      ? detail.income_type_description.name
                      : ''
                  "
                  no-data-text="Malumot topilmadi"
                  label="Kategoriya"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-text-field
                  dense
                  :value="
                    detail.income_type_description !== undefined &&
                    detail.income_type_description !== null
                      ? detail.income_type_description.name
                      : ''
                  "
                  label="SubKategoriya"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pl-0 py-0">
                <v-textarea
                  outlined
                  disabled
                  label="Comment"
                  v-model="detail.comment"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  created() {
    this.$store.dispatch('getIncomeListDetails', this.$route.params.id)
  },
  computed: {
    detail() {
      return this.$store.state.requests.incomeListDetails
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  filters: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Daromad Malumotlari' }])
  }
}
</script>
